import { BlockContentProps } from '@sanity/block-content-to-react';
import { H4, H5 } from 'components/headings';
import { Text } from 'components/ui/text';
import Link from 'next/link';
import React from 'react';

const BlockRenderer = ({ children, node }: any) => {
  const style = node.style || 'normal';

  switch (style) {
    case 'h2':
      return <H4 as='h2'>{children}</H4>;
    case 'h3':
      return <H5 as='h3'>{children}</H5>;
    case 'large':
      return <Text size='xLarge'>{children}</Text>;
    default:
      return <Text>{children}</Text>;
  }
};

export const serializers: BlockContentProps['serializers'] = {
  types: {
    block: BlockRenderer,
  },
  marks: {
    link: ({ mark, children }) => {
      // Read https://css-tricks.com/use-target_blank/
      const { blank, href } = mark;
      return blank ? (
        <a href={href} target='_blank' rel='noopener noreferrer'>
          {children}
        </a>
      ) : (
        <a href={href}>{children}</a>
      );
    },
    internalLink: ({ mark, children }) => {
      if (mark.downloadResourceUrl) {
        return (
          <a
            href={mark.downloadResourceUrl}
            target='_blank'
            rel='noopener noreferrer'
          >
            {children}
          </a>
        );
      }
      if (mark.slug) {
        const { slug = {}, blank } = mark;
        const href = `/${slug}`;
        return (
          <Link href='/[slug]' as={href}>
            {blank ? (
              <a target='_blank' rel='noopener noreferrer'>
                {children}
              </a>
            ) : (
              <a>{children}</a>
            )}
          </Link>
        );
      }

      return (
        <span>
          Unknown mark: <code>{mark._type}</code>.
        </span>
      );
    },
  },
};
