import BlockContent from '@sanity/block-content-to-react';
import { getDownloadResourceByTag, getHomePage } from 'api/cms';
import CreateAccountBanner from 'components/create-account-banner';
import Download from 'components/download';
import DownloadAppBanner from 'components/download-app-banner';
import DownloadSection from 'components/download-section';
import FaqBanner from 'components/faq-banner';
import Hero from 'components/hero';
import Layout from 'components/layout';
import SEO from 'components/seo';
import { useAnalytics } from 'hooks/use-analytics';
import { GetStaticProps, NextPage } from 'next';
import Head from 'next/head';
import React from 'react';
import { useEffectOnce } from 'react-use';
import AnalyticsService, { ScreenNames } from 'services/analytics';
import { serializers } from 'services/sanity/serializers';
import { DownloadResourceByTag, HomePage } from 'types/sanity';

interface Props {
  page: HomePage;
  downloadResources: DownloadResourceByTag;
  preview?: boolean;
}

const Home: NextPage<Props> = (props) => {
  const { downloadResources, page } = props;
  const { logDownload } = useAnalytics();
  const hasDownloadResources =
    downloadResources && !!downloadResources.files?.length;

  useEffectOnce(() => {
    AnalyticsService.trackScreen(ScreenNames.choosePlan);
  });

  return (
    <>
      <SEO
        title=''
        description='What will you do if there is a bushfire? Create a bushfire survival plan in 15 minutes or less. Download the app or fill in the survey on the site.'
      />
      <Head>
        <title>My Bushfire Plan WA: Prepare for an Emergency - DFES</title>
      </Head>
      <Layout preview={props.preview}>
        <Hero>
          <BlockContent blocks={page.text} serializers={serializers} />
        </Hero>

        {/* Promo banners */}
        <DownloadAppBanner />
        <FaqBanner />
        <CreateAccountBanner />

        {hasDownloadResources && (
          <DownloadSection>
            {downloadResources.files.map(({ title, description, asset }) => (
              <Download
                key={asset._id}
                title={title}
                description={description}
                file={asset}
                onClick={logDownload}
              />
            ))}
          </DownloadSection>
        )}
      </Layout>
    </>
  );
};

export const getStaticProps: GetStaticProps<Props> = async ({
  preview = false,
}) => {
  const [homePage, downloadResources] = await Promise.all([
    getHomePage(preview),
    getDownloadResourceByTag('homepage', preview),
  ]);

  return {
    props: {
      page: homePage,
      downloadResources,
      preview,
    },
  };
};

export default Home;
