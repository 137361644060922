import PropTypes from 'prop-types'; // ES6
import React, { FC, MouseEvent, useCallback } from 'react';
import { AssetBase } from 'types/sanity';
import {
  Description,
  DownloadStyled,
  Icon,
  Link,
  Title,
} from './download.styled';

interface Props {
  title: string;
  file: AssetBase;
  description?: string;
  onClick?: (title: string, event: MouseEvent<HTMLAnchorElement>) => void;
}

const Download: FC<Props> = ({ title, description, file, onClick }) => {
  const titleId = `title-${file.assetId}`;
  const titleDesription = description && `desc-${file.assetId}`;
  const fileSizeMB = (file.size / 1000000).toFixed(1);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      onClick && onClick(title, event);
    },
    [onClick, title]
  );

  return (
    <Link
      href={`${file.url}?dl=`}
      download={file.originalFilename}
      type={file.mimeType}
      aria-labelledby={titleId}
      aria-describedby={titleDesription}
      onClick={handleClick}
    >
      <DownloadStyled>
        <Title as='h3' id={titleId}>
          {title} ({fileSizeMB}MB&nbsp;{file.extension.toUpperCase()})
        </Title>
        <Icon aria-hidden />
        {description && (
          <Description id={titleDesription}>{description}</Description>
        )}
      </DownloadStyled>
    </Link>
  );
};

Download.propTypes = {
  title: PropTypes.string.isRequired,
  file: PropTypes.shape({
    _createdAt: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired,
    _rev: PropTypes.string.isRequired,
    _type: PropTypes.string.isRequired,
    _updatedAt: PropTypes.string.isRequired,
    assetId: PropTypes.string.isRequired,
    extension: PropTypes.string.isRequired,
    mimeType: PropTypes.string.isRequired,
    originalFilename: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    sha1hash: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    uploadId: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  description: PropTypes.string,
};

export default Download;
