import { ReactComponent as HelpIcon } from 'assets/icons/icon-undecided.svg';
import Button from 'components/button';
import { H2 } from 'components/headings';
import SwitchTheme from 'components/switch-theme';
import { Section } from 'components/ui/section';
import { Text } from 'components/ui/text';
import Link from 'next/link';
import React, { FC } from 'react';
import { ActionWrapper, IconWrapper, StyledSection } from './faq-banner.styles';

const FaqBanner: FC = () => {
  return (
    <SwitchTheme to='light'>
      <Section>
        <StyledSection>
          <div>
            <H2>How do I choose my plan? What is the safest choice?</H2>
            <Text size='large'>
              You create your own plan by considering the unique set of
              circumstances that relate to you and your household.
            </Text>
          </div>
          <ActionWrapper>
            <IconWrapper>
              <HelpIcon title='Help' />
            </IconWrapper>
            <Link href='/faq' passHref>
              <Button size='small' as='a'>
                See More FAQs
              </Button>
            </Link>
          </ActionWrapper>
        </StyledSection>
      </Section>
    </SwitchTheme>
  );
};

export default FaqBanner;
