import { SectionWrapper } from 'components/ui/section-wrapper';
import { rem } from 'polished';
import styled from 'styled-components';

export const StyledSection = styled(SectionWrapper)`
  display: grid;

  img {
    display: block;
    width: 100%;

    ${({ theme }) => theme.mediaQueries.medium} {
      max-width: 180px;
    }
  }

  ${({ theme }) => theme.mediaQueries.medium} {
    grid-gap: ${rem(80)};
    grid-template-columns: repeat(auto-fill, minmax(180px, auto));
  }
`;

export const ActionWrapper = styled.div`
  display: grid;
  grid-gap: ${rem(16)};
  grid-template-rows: repeat(2, auto);
  grid-template-columns: 1fr;
  text-align: center;
`;

export const IconWrapper = styled.div`
  position: relative;
  display: none;

  ${({ theme }) => theme.mediaQueries.medium} {
    display: block;
    justify-self: center;
    width: 180px;
    height: 180px;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
