import Button from 'components/button';
import { H2 } from 'components/headings';
import SwitchTheme from 'components/switch-theme';
import { SectionWrapper } from 'components/ui/section-wrapper';
import { Text } from 'components/ui/text';
import Link from 'next/link';
import { FC } from 'react';
import APP_ROUTES from 'utils/routes';
import { StyledSection } from './create-account-banner.styles';

const CreateAccountBanner: FC = () => {
  return (
    <SwitchTheme to='light'>
      <StyledSection textAlign='center'>
        <SectionWrapper>
          <H2>Create an account</H2>
          <Text size='large'>
            Save and keep your plan up-to-date throughout the year and get
            reminders to help you prepare your property for bushfire season.
          </Text>
          <Link href={APP_ROUTES.users.signUp} passHref>
            <Button size='small' as='a'>
              Create an account
            </Button>
          </Link>
        </SectionWrapper>
      </StyledSection>
    </SwitchTheme>
  );
};

export default CreateAccountBanner;
