import { Section } from 'components/ui/section';
import { rem } from 'polished';
import styled from 'styled-components';

export const StyledSection = styled(Section)`
  display: none;

  ${({ theme }) => theme.mediaQueries.medium} {
    display: flex;
    align-items: flex-end;
    min-height: 380px;
    background-image: url(${require('../../assets/images/create-account-banner-v2.jpg')});
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;

    & > div {
      max-width: calc(500px + (var(--padding) * 2));
      padding-top: ${rem(30)};
      padding-bottom: ${rem(30)};
    }
  }

  ${({ theme }) => theme.mediaQueries.large} {
    min-height: 420px;
    background-size: contain;

    & > div {
      max-width: calc(600px + (var(--padding) * 2));
      padding-bottom: ${rem(60)};
    }
  }

  ${({ theme }) => theme.mediaQueries.xlarge} {
    min-height: 600px;
    background-size: cover;

    & > div {
      padding-bottom: ${rem(100)};
    }
  }
`;
