import Button from 'components/button';
import { H2 } from 'components/headings';
import SwitchTheme from 'components/switch-theme';
import { SectionWrapper } from 'components/ui/section-wrapper';
import Link from 'next/link';
import React, { FC } from 'react';
import APP_ROUTES from 'utils/routes';
import { StyledSection } from './download-section.styles';

const DownloadSection: FC = ({ children }) => {
  return (
    <SwitchTheme to='light'>
      <StyledSection textAlign='center'>
        <SectionWrapper>
          <H2>Bushfire preparation checklists and resources</H2>
          {children}
          <Link href={APP_ROUTES.resources} passHref>
            <Button size='small' as='a'>
              View all our bushfire emergency resources
            </Button>
          </Link>
        </SectionWrapper>
      </StyledSection>
    </SwitchTheme>
  );
};

export default DownloadSection;
