import { useCallback } from 'react';
import { logDownloadResource, logViewPlanStep } from 'services/analytics';
import { useStore } from 'store';
import { getPlanStepId } from 'utils/plan-helpers';

export function useAnalytics() {
  const getPlanDraft = useStore((state) => state.getPlanDraft);

  /** Behaviour Tracking: Amount of downloads per downloadable content  */
  const logDownload = useCallback(
    (title: string) => {
      const draftPlan = getPlanDraft();
      logDownloadResource(title, draftPlan);
    },
    [getPlanDraft]
  );

  const logPlanStepView = useCallback(
    (step: number) => {
      const draftPlan = getPlanDraft();
      const stepId = getPlanStepId(step, draftPlan.type);
      logViewPlanStep(step, stepId, draftPlan);
    },
    [getPlanDraft]
  );

  return {
    logDownload,
    logPlanStepView,
  };
}
