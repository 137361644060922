import { ReactComponent as DownloadIcon } from 'assets/icons/icon-download.svg';
import { math, padding, rem, transparentize } from 'polished';
import styled from 'styled-components';

const BOX_PADDING = [rem(15), rem(65), rem(15), rem(40)];
const ICON_RIGHT = rem(25);

export const Icon = styled(DownloadIcon)`
  position: absolute;
  top: 50%;
  right: ${ICON_RIGHT};
  display: inline-block;
  width: ${rem(20)};
  height: ${rem(20)};
  transform: translateY(-50%);
`;

export const DownloadStyled = styled.figure`
  position: relative;
  margin: 0;
  color: ${({ theme }) => theme.components.download.text};
  text-align: left;
  background-color: ${({ theme }) => theme.components.download.background};
  border: 2px solid ${({ theme }) => theme.colors.palette.greyLight};
  border-radius: ${({ theme }) => math(theme.borderRadius.medium + ' * 2')};
  transition:
    box-shadow 150ms ease-in-out,
    border-color 150ms ease-in-out,
    background-color 150ms ease-in-out,
    color 150ms ease-in-out;
  ${padding(...BOX_PADDING)};

  ${({ theme }) => theme.mediaQueries.medium} {
    border-radius: ${({ theme }) => theme.borderRadius.large};
  }
`;

export const Link = styled.a`
  display: block;
  margin: ${rem(12)} 0;
  text-decoration: none;

  ${({ theme }) => theme.mediaQueries.medium} {
    margin: ${rem(12)};
  }

  &:hover {
    ${DownloadStyled} {
      color: ${({ theme }) => theme.colors.palette.white};
      background-color: ${({ theme }) => theme.colors.palette.redDark};
      border-color: ${({ theme }) => theme.colors.palette.redDark};
    }
  }

  &:focus {
    outline: 0;

    ${DownloadStyled} {
      border-color: ${({ theme }) => theme.colors.palette.redDark};
      box-shadow: 0 0 8px
        ${({ theme }) => transparentize(0.2, theme.colors.palette.redDark)};
    }
  }

  &:focus-visible {
    box-shadow: none;
  }
`;

export const Title = styled.strong`
  margin: 0;
  color: inherit;
  font-size: 1rem;
  line-height: 1.2;
  text-decoration: none;
`;

export const Description = styled.figcaption`
  margin-top: 4px;
  font-size: ${rem(15)};
  line-height: 1.3;
`;
