import { SectionWrapper } from 'components/ui/section-wrapper';
import { rem } from 'polished';
import styled from 'styled-components';

export const StyledSectionWrapper = styled(SectionWrapper)`
  display: grid;
  grid-gap: ${rem(40)};
  align-items: center;

  img {
    display: block;
    width: 100%;

    ${({ theme }) => theme.mediaQueries.medium} {
      max-width: 180px;
    }
  }

  ${({ theme }) => theme.mediaQueries.medium} {
    grid-gap: ${rem(80)};
    grid-template-columns: repeat(auto-fill, minmax(180px, auto));
  }
`;

export const StyledMobileImage = styled.img`
  ${({ theme }) => theme.mediaQueries.medium} {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: ${({ theme }) => theme.borderRadius.medium};
  }
`;

export const DownloadButtons = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: ${rem(16)};

  a,
  img {
    display: block;
    width: auto;

    ${({ theme }) => theme.mediaQueries.medium} {
      height: 54px;
    }
  }
`;
