import { H2 } from 'components/headings';
import { Section } from 'components/ui/section';
import { rem } from 'polished';
import styled from 'styled-components';

export const StyledSection = styled(Section)`
  ${H2} {
    margin: 0 auto;
    margin-bottom: ${rem(30)};
    text-align: left;
  }

  ${({ theme }) => theme.mediaQueries.medium} {
    ${H2} {
      max-width: 400px;
      margin-bottom: ${rem(60)};
      text-align: center;
    }
  }
`;
