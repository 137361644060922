import { H2 } from 'components/headings';
import { HideVisually } from 'components/hide-visually';
import SwitchTheme from 'components/switch-theme';
import { Section } from 'components/ui/section';
import { Text } from 'components/ui/text';
import { FC } from 'react';
import { baseUnits } from 'styles/media-queries';
import { settings } from 'utils/config';
import {
  DownloadButtons,
  StyledMobileImage,
  StyledSectionWrapper,
} from './download-app-banner.styles';

const DownloadAppBanner: FC = () => {
  return (
    <SwitchTheme to='dark'>
      <Section>
        <StyledSectionWrapper>
          <picture>
            <source
              srcSet={require('../../assets/images/download-app-large.png')}
              media={`(min-width: ${baseUnits.medium})`}
            ></source>
            <StyledMobileImage
              src={require('../../assets/images/download-app-small.png')}
              alt=''
            />
          </picture>
          <div>
            <H2>Download My Bushfire Plan App</H2>
            <Text size='large'>
              Access your bushfire plan anytime - even without an internet
              connection.
            </Text>
            <DownloadButtons>
              <a
                href={settings.url.appleStore}
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  src='/badge-app-store.svg'
                  height={40}
                  alt='Download on the App Store'
                />
                <HideVisually>(opens in new window)</HideVisually>
              </a>
              <a
                href={settings.url.playStore}
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  src='/badge-google-play.svg'
                  height={40}
                  alt='Get it on Google Play'
                />
                <HideVisually>(opens in new window)</HideVisually>
              </a>
            </DownloadButtons>
          </div>
        </StyledSectionWrapper>
      </Section>
    </SwitchTheme>
  );
};

export default DownloadAppBanner;
